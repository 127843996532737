<template>
  <v-sheet max-width="700px" class="mx-auto">
    <v-container fluid>
      <v-row>
        <v-col class="text-h5 font-weight-bold"> SAGC Songbook </v-col>
      </v-row>
      <v-row class="mt-n2">
        <v-col>
          <v-text-field
            @keydown.enter="search"
            placeholder="Search by title, lyrics or song number"
            clearable
            v-model="searchText"
            variant="outlined"
          ></v-text-field>
          <p class="d-flex justify-center mt-n2">
            <v-btn color="success" @click="search">Search</v-btn> &nbsp;
            <v-btn @click="reset" variant="text">Reset</v-btn>
          </p>
        </v-col>
      </v-row>

      <v-row v-if="hasResults" class="mt-10">
        <v-col class="d-flex justify-center">
          <v-btn-toggle v-model="selectedSongBook" group density="compact">
            <v-btn v-for="sb in songBooks" :key="sb" :value="sb">
              {{ sb }} {{ filteredSongCount(sb) }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-row density="compact" v-if="hasResults">
        <v-col density="compact">
          <v-data-table
            :headers="headers"
            :items="filtered[selectedSongBook]"
            hide-actions
            item-key="title"
            :loading="searching"
            items-per-page="50"
            class="mt-n10"
            density="compact"
          >
            <template v-slot:[`item.action`]="{ item }">
              <v-btn size="small" elevation="0" variant="tonal" @click="songClicked(item)"
                >View</v-btn
              >
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-dialog v-model="showLyrics" max-height="90%" max-width="600px">
      <v-sheet v-if="showLyrics">
        <LyricsDialog :currentSong="currentSong" @close="showLyrics=false"></LyricsDialog>
      
      </v-sheet>
      </v-dialog>
      
      <v-snackbar v-model="snackbarVisible">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbarVisible = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>      
    </v-container>
  </v-sheet>
</template>

<script>
import { isUndefined } from "lodash";
import LyricsDialog from "../components/LyricsDialog.vue";
const _ = require("lodash");

export default {
  components: {
    LyricsDialog,
  },
  beforeRouteUpdate: function(to, from, next) {
    var params = to.params
    var songbook = params.songbook
    var id = params.id
    this.showSongItem(songbook,id)
    next()
  },  
  mounted: function () {
    var self = this;
    this.getSongs().then((result) => {
      self.songs = result.songs;
      self.reset();
    });
  },
  computed: {
    hasResults: function () {
      var total = 0;
      _.map(this.filtered, (o) => {
        total = total + o.length;
      });
      return total > 0;
    },
    clipboardText: function () {
      return [this.currentSong.title, this.currentSong.lyrics].join("\n\n");
    },
  },
  data: function () {
    return {
      songs: [],
      filtered: {},
      searcher: null,
      searchText: "",
      searching: false,
      searchingOptions: {},
      showLyrics: false,
      currentSong: {},
      songBooks: [],
      maxSearch: 50,
      tab: "1",
      selectedSongBook: "",
      headers: [
        { text: "Title", value: "title" },
        { text: "Action", value: "action", width: "50px" },
      ],
      snackbarVisible:false,
      snackbarText:''
    };
  },

  methods: {
    showSongItem: function(songbook,id){
      var songBook = this.songBooks[songbook]
      var song = _.find(songBook, {id:id})
      this.currentSong=song
      this.showLyrics=true
    },

    songClicked: function (data) {
      this.currentSong = data;
      this.showLyrics = true;
    },

    message: function(message){
      this.snackbarText=message
      this.snackbarVisible=true
    },


    filteredSongCount: function (sb) {
      if (isUndefined(this.filtered[sb])) {
        return "";
      }
      return "(" + this.filtered[sb].length + ")";
    },

    reset: function () {
      var self = this;
      var filtered = {};
      this.songBooks = _.uniq(_.map(this.songs, "songbook")).reverse();

      _.map(this.songBooks, (sb) => {
        filtered[sb] = _.sortBy(_.filter(self.songs, { songbook: sb }), (o) =>
          o.title_text.toLowerCase()
        );
      });

      this.selectedSongBook = this.songBooks[0];
      this.filtered = filtered;
      this.searchText = "";
    },

    search: function () {
      var self = this;

      if (this.searchText == null || this.searchText == "") {
        self.reset();
        return;
      }

      var searchText = this.searchText.toLowerCase();
      searchText = searchText.replace(/[^a-z0-9]/gi, "");

      if (searchText.length < 3) {
        self.filtered = {};
        return;
      }

      this.searching = true;
      var filtered = {};

      var title_matches = _.filter(self.songs, (o) => {
        // var result = o.search.indexOf(searchText) > 0;
        var result = o.title.toLowerCase().indexOf(searchText) >= 0;
        if (result) {
          return true;
        }
        return false;
      });

      var title_body_matches = _.filter(self.songs, (o) => {
        var result = o.search.indexOf(searchText) > 0;
        if (result) {
          return true;
        }
        return false;
      });

      title_matches = _.compact(title_matches);
      title_body_matches = _.compact(title_body_matches);

      _.map(self.songBooks, (sb) => {
        filtered[sb] = _.filter(title_matches, { songbook: sb });
        filtered[sb].push(..._.filter(title_body_matches, { songbook: sb }));
        filtered[sb] = _.uniqBy(filtered[sb], (o) => {
          return o.title;
        });
      });

      self.filtered = filtered;
      
      // self.selectedSongBook = self.songBooks[0];
      self.selectActiveSongbook()
      self.searching = false;
    },

    selectActiveSongbook(){
      var self = this
      _.map(Object.keys(this.filtered),(sb)=>{
        if(self.filtered[sb].length>0){
          self.selectedSongBook = sb
        }
      })
    },

    getSongs: async function () {
      var response = await fetch(`/songs.json`);
      return await response.json();
    },
  },
};

</script>
