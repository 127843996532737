<template>
  <v-app>
    <v-app-bar scroll-behavior="hide" density="compact">
      <template v-slot:append>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      </template>

      <v-img src="/img/icons/android-chrome-192x192.png"></v-img>
      
      

    </v-app-bar>

    <v-navigation-drawer v-model="drawer" location="right">
      <v-list-item link title="Home"></v-list-item>
      <!-- <v-list-item link title="Categories"></v-list-item> -->
      <div class="text-subtitle-2 d-flex justify-center mt-6">v2024.06.15</div>
    </v-navigation-drawer>
    <v-main>
      <router-view />
      
    </v-main>
    <v-footer>
    
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: function () {
    return {
      drawer: false,
    };
  },
};
</script>
