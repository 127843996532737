<template>
  <v-card max-height="90%">
    <v-card-title class="text-subtitle-1 text-wrap font-weight-bold mb-3">
      {{ song.title }}
    </v-card-title>
    <v-card-text>
      <v-container class="pa-0 mt-n4">
        <v-row>
          <v-col class="pa-0">
            <v-textarea
              v-model="song.sequence"
              auto-grow
              rows="1"
              label="Sequence"
              readonly
              density="compact"
            >
            </v-textarea>
          </v-col>
        </v-row>
        <v-row class="mt-n2 pa-0">
          <v-col class="pa-0">
            <v-textarea v-model="song.lyrics" auto-grow label="Lyrics" readonly>
            </v-textarea> </v-col
        ></v-row>
        <v-row v-show="false">
          <v-col>
            <v-textarea
              id="clipboardtext"
              v-model="clipboardText"
              auto-grow
              label="Clipboard Lyrics"
              readonly
            >
            </v-textarea>
          </v-col>
        </v-row>
        <v-row class="mt-n2 pa-0">
          <v-col class="d-flex justify-center">
            <v-btn @click="copyToClipboard(song)" variant="tonal"
              >Copy To Clipboard</v-btn
            >
            &nbsp;
            <v-btn @click="closeLyrics" variant="tonal">Close</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-snackbar v-model="snackbarVisible">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbarVisible = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
const _ = require("lodash");
const isUndefined = _.isUndefined;

export default {
  props: {
    currentSong: Object,
  },
  setup(){

    var snackbarText = ''
    var snackbarVisible = false
    var song = {}
    var clipboardText = ''

    return {
      snackbarText,
      snackbarVisible,
      clipboardText,
      song
    }
  },
  mounted:function(){
    this.song = this.currentSong
  },
  computed: {
    sequenceLength: function(){
      if (!isUndefined(this.song.sequence)){
        return this.song.sequence.length
      }

      return 0
    }
  },
  methods: {
    message: function (message) {
      this.snackbarText = message;
      this.snackbarVisible = true;
    },
    closeLyrics: function () {
      this.$emit("close");
    },
    copyToClipboard: function () {
      var self = this;
      var element = document.getElementById("clipboardtext");
      copyContent2Clipboard2(element).then(() => {
        self.message("Copied to clipboard.");
      });
    },
  },
};

async function copyContent2Clipboard2(el) {
  el.select();
  el.setSelectionRange(0, 99999);
  return await navigator.clipboard.writeText(el.value).then(() => {
    //Deselect text
    if (window.getSelection) {
      if (window.getSelection().empty) {
        // Chrome
        window.getSelection().empty();
      } else if (window.getSelection().removeAllRanges) {
        // Firefox
        window.getSelection().removeAllRanges();
      }
    } else if (document.selection) {
      // IE?
      document.selection.empty();
    }
  });
}
</script>
